'use client'

import { useTranslations } from 'next-intl'

import { useAuthUser } from '@/src/entities/auth-user/hooks/useAuthUser'
import { Button } from '@/src/shared/ui/Button'
import { useAuthLogin } from '@/src/widgets/Auth/hooks/useAuthLogin'

const LoginButton = () => {
  const t = useTranslations('Common')
  const { isAuth } = useAuthUser()
  const { onAuthModalChange } = useAuthLogin()

  if (isAuth) return

  return <Button onClick={onAuthModalChange}>{t('login')}</Button>
}

export default LoginButton
