'use client'

import './GameFrame.scss'

import { useSearchParams } from 'next/navigation'
import { useTranslations } from 'next-intl'
import { useWindowSize } from 'react-use'

import { pathnames } from '@/src/app/config/pathnames'
import { BreakpointsMd } from '@/src/shared/config/variables'
import { IAspectRatio } from '@/src/shared/model'
import { Button } from '@/src/shared/ui/Button'
import { Icon } from '@/src/shared/ui/Icon'
import { IFrame } from '@/src/shared/ui/IFrame'
import { LocalizedLink } from '@/src/shared/ui/LocalizedLink'
import { VideoOrImage } from '@/src/shared/ui/VideoOrImage'

import { RUN_GAME_QUERY } from '../../config'
import { useUserPlayGame } from '../../hooks/useUserPlayGame'
import { IGameFrame } from '../../model'
import { aspectRatio } from './config'

export type IGameFrameProps = {
  className?: string
  game: IGameFrame
  aspectRatio: IAspectRatio
  priority?: boolean
  pathname?: keyof typeof pathnames
  sizes?: string
  enableCheckingIsPlaying?: boolean
}

const GameFrameContent = ({
  game,
  priority = false,
  pathname,
  sizes = '100vw',
  enableCheckingIsPlaying = false,
}: IGameFrameProps) => {
  const { slug, video, image, iframe, imageMobile, videoMobile } = game
  const t = useTranslations('Common')
  const { width } = useWindowSize()
  const searchParams = useSearchParams()

  const runGameQuery = searchParams.get(RUN_GAME_QUERY)
  const isThisGame = runGameQuery === slug

  const isDesktop = width >= parseInt(BreakpointsMd)

  useUserPlayGame(enableCheckingIsPlaying, isThisGame ? slug : null)

  if (isThisGame && iframe && isDesktop) {
    return (
      <div className="game-frame__ig">
        <IFrame enableFullScreenMode src={iframe} aspectRatio={aspectRatio} />
        <LocalizedLink
          scroll={false}
          href={{
            pathname,
            params: { slug },
          }}
          className="game-frame__close-game-button"
        >
          <Icon width={25} height={25} name="cross-icon" />
        </LocalizedLink>
      </div>
    )
  }

  const handleClick = (e: React.MouseEvent) => {
    if (!isDesktop) {
      e.preventDefault()

      if (iframe) {
        window.open(iframe, '_blank')
      }
    }
  }

  const media = (
    <VideoOrImage
      priority={priority}
      aspectRatio={aspectRatio}
      video={video}
      videoMobile={videoMobile}
      image={image}
      imageMobile={imageMobile}
      sizes={sizes}
      alt={`image-${slug}`}
    />
  )

  return (
    <>
      {iframe ? (
        <LocalizedLink
          scroll={false}
          onClick={handleClick}
          href={{
            pathname,
            params: { slug },
            query: {
              [RUN_GAME_QUERY]: slug,
            },
          }}
          className="game-frame__media"
        >
          {media}

          <div className="game-frame__button">
            <Button tag="div" size="xl">
              {t('playGame')}
            </Button>
          </div>
        </LocalizedLink>
      ) : (
        <div style={{ pointerEvents: 'none' }} className="game-frame__media">
          {media}
        </div>
      )}
    </>
  )
}

export default GameFrameContent
