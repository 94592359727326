import './GameFrame.scss'

import cn from 'classnames'
import dynamic from 'next/dynamic'
import { Suspense } from 'react'

import { pathnames } from '@/src/app/config/pathnames'
import { IGameFrame } from '@/src/entities/game/model'
import { useAspectRatio } from '@/src/shared/hooks/useAspectRatio'
import { Container } from '@/src/shared/ui/Container'

import { aspectRatio } from './config'

const GameFrameContent = dynamic(() => import('./GameFrameContent'), { ssr: false })

export type IGameFrameProps = {
  withoutContainer?: boolean
  className?: string
  game?: IGameFrame
  priority?: boolean
  pathname?: keyof typeof pathnames
  sizes?: string
  loading?: boolean
  enableCheckingIsPlaying?: boolean
}

const GameFrame = ({
  enableCheckingIsPlaying = false,
  withoutContainer = false,
  className,
  game,
  priority = false,
  pathname = '/games/[slug]',
  sizes,
  loading,
}: IGameFrameProps) => {
  const { id, styles } = useAspectRatio(aspectRatio)

  if (loading || !game) {
    return (
      <>
        {styles}
        <div data-id={id} className="game-frame-skeleton" />
      </>
    )
  }

  return (
    <>
      {styles}

      <section
        className={`${cn('game-frame', {
          [`${className}`]: !!className,
        })}`}
      >
        <Container withoutPaddings={withoutContainer} size="infinity">
          <div data-id={id} className="game-frame__inner">
            <Suspense>
              <GameFrameContent
                enableCheckingIsPlaying={enableCheckingIsPlaying}
                sizes={sizes}
                pathname={pathname}
                priority={priority}
                aspectRatio={aspectRatio}
                game={game}
              />
            </Suspense>
          </div>
        </Container>
      </section>
    </>
  )
}

export default GameFrame
