import type { IGame, IGameDetail } from '../../model'
import type { IGameDetailDto, IGameDto } from '../dto'

export const mapGame = (dto: IGameDto): IGame => ({
  id: dto.id,
  slug: dto.slug,
  image: dto.preview,
  video: dto.video,
  imageMobile: dto.mobile_preview,
  videoMobile: dto.mobile_video,
  iframe: dto.iframe ?? null, // playtest
  title: dto.title ?? null, // playtest
})

export const mapGameDeatail = (dto: IGameDetailDto): IGameDetail => {
  const { data } = dto

  return {
    id: data?.id ?? 0,
    slug: data?.slug ?? '',
    metaTitle: data?.meta_title ?? data?.title ?? null,
    metaDescription: data?.meta_description ?? data?.meta_title ?? data?.title ?? null,
    image: data?.preview ?? null,
    video: data?.video ?? null,
    iframe: data?.iframe ?? null,
    characteristics: [
      {
        id: 1,
        value: data?.volatility ?? null,
        icon: 'volatility',
      },
      {
        id: 2,
        value: data?.rtp ?? null,
        icon: 'back',
      },
      {
        id: 3,
        value: data?.max_multiplier ?? null,
        icon: 'cross',
      },
    ],
    title: data?.description ?? null,
    introTitle: data?.title ?? null,
    introImage: data?.description_image ?? null,
    promoPack: data?.promo_pack ?? null,
    gameFrame: {
      id: data?.id ?? 0,
      slug: data?.slug ?? '',
      image: data?.preview ?? null,
      video: data?.video ?? null,
      iframe: data?.iframe ?? null,
      imageMobile: data?.mobile_preview ?? null,
      videoMobile: data?.mobile_video ?? null,
    },
    gameName: data?.mobile_title ?? null,
    imageMobile: data?.mobile_preview ?? null,
    videoMobile: data?.mobile_video ?? null,
  }
}
