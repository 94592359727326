'use client'

import useSWR from 'swr'

import { useAuthUser } from '@/src/entities/auth-user/hooks/useAuthUser'

import { setPlayingGamePlaytest } from '../api'

export const useUserPlayGame = (enabled: boolean = false, gameSlug: string | null) => {
  const { isAuth } = useAuthUser()
  const { data } = useSWR(enabled && isAuth ? gameSlug : null, () => setPlayingGamePlaytest())

  return {
    isSettedPlaying: !!data?.isSetPlayingSuccess,
    gameSlug,
  }
}
