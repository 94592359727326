import { apiClient, apiClientPlaytest, IApiClientAdditionalOptions } from '@/src/shared/api'
import { mapCollection } from '@/src/shared/lib/mapCollection'

import type { IGameDetail, IGames } from '../model'
import type { IGameDetailDto, IGamesDto } from './dto'
import { mapGame, mapGameDeatail } from './mapper'
import type { IGameDetailParams, IGamesParams } from './params'
import type { IGamesQuery } from './query'

type IGamesConfig = {
  params?: IGamesParams
  query?: IGamesQuery
  nextConfig?: IApiClientAdditionalOptions
}

export const getGames = async ({
  params,
  query,
  nextConfig,
}: IGamesConfig = {}): Promise<IGames> => {
  const { slug } = params ?? {}
  const { ids } = query ?? {}

  let result: IGamesDto

  if (slug) {
    result = await apiClient.get<IGamesDto>(`games/${slug}/similar`)
  } else {
    result = await apiClient.get<IGamesDto>(
      'games',
      {
        ids: ids ?? [],
      },
      nextConfig,
    )
  }

  return {
    data: mapCollection(result.data, mapGame),
  }
}

export const getGamesPlaytest = async (): Promise<IGames> => {
  const result = await apiClientPlaytest.get<IGamesDto>('main/games')

  return {
    data: mapCollection(result.data, mapGame),
  }
}

type IGameDetailConfig = {
  params?: IGameDetailParams
}

export const getGameDetail = async ({ params }: IGameDetailConfig = {}): Promise<IGameDetail> => {
  const { slug } = params ?? {}

  const result = await apiClient.get<IGameDetailDto>(`games/${slug}`)

  return mapGameDeatail(result)
}

export const setPlayingGamePlaytest = async () => {
  const result = await apiClientPlaytest.post<{ status: 'success' | 'error' }>(
    `customers/set/online`,
  )

  return {
    isSetPlayingSuccess: result.status === 'success',
  }
}
