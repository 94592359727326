'use client'

import { useLocale } from 'next-intl'
import useSWR from 'swr'

import { ILang } from '@/src/app/model/lang'
import { apiClientPlaytest } from '@/src/shared/api'

import { getGamesPlaytest } from '../api'
import { GAMES_FRAMES_PLAYTEST_REVALIDATE_KEY } from '../config'

export const useGamesFramesPlaytest = () => {
  const locale = useLocale()
  apiClientPlaytest.setLocale(locale as ILang)

  const {
    data: { data: games },
  } = useSWR([GAMES_FRAMES_PLAYTEST_REVALIDATE_KEY], () => getGamesPlaytest(), {
    suspense: true,
  })

  return { games }
}
